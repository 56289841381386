<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            Sales Return Report
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card flat>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="Atleast 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="searchAH"
                    @input="searchItem"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>

            <v-data-table
              :headers="headers"
              :items="form.items.data"
              item-key="sale_bill_no"
              :server-items-length="form.items.meta.total"
              :search="search"
              :options.sync="pagination"
              :loading="form.loading"
              :footer-props="footerProps"
              :expanded.sync="expanded"
              :single-expand="singleExpand"
              show-expand
            >
              <template
                v-slot:item.data-table-expand="{ expand, isExpanded, item }"
              >
                <span v-if="item">
                  <v-icon
                    style="cursor: pointer"
                    color="error"
                    v-if="isExpanded"
                    @click="expand(!isExpanded)"
                  >
                    indeterminate_check_box
                  </v-icon>
                  <v-icon
                    style="cursor: pointer"
                    color="primary"
                    v-else
                    @click="expand(!isExpanded)"
                  >
                    add_circle
                  </v-icon>
                </span>
                <span v-else>
                  <v-icon style="cursor: no-drop"> add_circle </v-icon>
                </span>
              </template>
              <template v-slot:item.name="{ item }">
                <td>{{ item.ledger_head ? item.ledger_head.name : '' }}</td>
              </template>
              <template v-slot:item.sales_bill_no="{ item }">
                <td>{{ item.sale_bill_no }}</td>
              </template>
              <template v-slot:item.voucher_type="{ item }">
                <td>{{ item.voucher_type.name }}</td>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon color="primary" @click="print(item.sale_bill_no)">print</v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td style="padding: 0px" :colspan="headers.length">
                  <table width="100%" class="in-table">
                    <thead>
                      <tr>
                        <td class="text-xs-left"><strong>#</strong></td>
                        <td class="text-xs-left"><strong>Item</strong></td>
                        <td class="text-xs-left"><strong>WareHouse</strong></td>
                        <td class="text-xs-left">
                          <strong>Return Date</strong>
                        </td>
                        <td class="text-xs-left"><strong>Quantity</strong></td>
                        <td class="text-xs-left"><strong>Rate </strong></td>
                        <td class="text-xs-left"><strong>Amount</strong></td>
                      </tr>
                      <tr
                        v-for="(x, i) in item.sale_returns"
                        :key="i"
                        style="height: 0px"
                        class="footerRow"
                      >
                        <td class="text-xs-left">{{ i + 1 }}</td>
                        <td class="text-xs-left">{{ x.item_name }}</td>
                        <td class="text-xs-left">{{ x.warehouse_name }}</td>
                        <td class="text-xs-left">{{ x.return_date }}</td>
                        <td class="text-xs-left">{{ x.qty.numberFormat() }}</td>
                        <td class="text-xs-left">
                          {{ x.rate.numberFormat() }}
                        </td>
                        <td class="text-xs-left">
                          {{ x.amount.numberFormat() }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="footerRow">
                        <td :colspan="6" class="text-xs-right">
                          Return Total Amount:
                        </td>
                        <td :colspan="2">
                          {{
                            returnTotalAmount(item.sale_returns).numberFormat()
                          }}
                        </td>
                      </tr>
                      <!-- <tr class="footerRow">
                      <td class="narration">Narration:</td>
                      <td class="narration" :colspan="8" width="100%">
                        {{ item.narration }}
                      </td>
                    </tr> -->
                    </tbody>
                  </table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { redirectDownloadUrl } from '@/library/helpers';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
        print_name: '',
        item_code: '',
        category_id: '',
        is_service_type: 0,
        purchase_rate: '',
        sales_rate: '',
        mrp_sales: '',
        min_sales_rate: '',
        is_taxable: 0,
        item_data: {
          warehouse_id: '',
          qty: '',
          rate: '',
          unit: '',
        },
      },
      '/api/account/inventory/sale-return'
    ),
    searchAH: '',
    expand: false,
    searchC: null,
    search: null,
    searchW: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
      // sortBy: 'id',
    },
    // categorySearchData: [],
    // wareHouseSearchData: [],
    categories: [],
    units: [],
    wareHouses: [],
    accountHeads: [],
    return_total_amount: 0,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Account Ledger', value: 'name', align: 'left' },
      {
        text: 'Sale Bill Date',
        value: 'sale_bill_date',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Sale Bill No',
        value: 'sales_bill_no',
        align: 'left',
        sortable: false,
      },
      { text: 'Bill Type', value: 'bill_type', align: 'left', sortable: false },
      {
        text: 'Voucher Type',
        value: 'voucher_type',
        align: 'left',
        sortable: false,
      },
      { text: 'Action', value: 'action', align: 'right', sortable: false },
    ],
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
    expanded: [],
    singleExpand: true,
  }),

  computed: {
    ...mapState(['batch']),
  },
  mounted() {
    // this.getUnits();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    print(bill_no) {
      console.log('printing....');
      redirectDownloadUrl({
        uri: '/download/sales-return-item',
        queryString: `billNo=${bill_no}`,
      });
    },
    returnTotalAmount(item) {
      let amount = 0;
      item.map((res) => {
        amount += parseFloat(res.amount);
      });
      return amount;
    },
  },
};
</script>
<style lang="scss">
tr.highlight {
  cursor: pointer;
}

.footerRow {
  height: 25px !important;
  border: none !important;
}

.in-table {
  /*width: 90%;*/

  margin: auto;
  border-collapse: collapse;
  background: rgba(184, 184, 184, 0.37);

  thead {
    /*height: 15px;*/
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }

  tbody td {
    /*background: red;*/
    height: 20px !important;
  }

  .narration {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
}
</style>
